const routes = [{
        path: "/request/thank-you",
        name: "Thanks for your trip request",
        component: () => import("@/views/landing/ThankYou.vue"),
    },
    {
        path: "/request/success",
        name: "Thanks for your trip request",
        component: () => import("@/views/landing/ThankYou.vue"),
    },
    {
        path: "/business/thank-you",
        name: "Thanks for your business request",
        component: () => import("@/views/landing/ThankYou.vue"),
    },
    {
        path: "/payment/thank-you",
        name: "Thank you for booking with us",
        component: () => import("@/views/landing/ThankYou.vue"),
    },
];

export default routes;